import React from 'react';
import ReactFullpage from '@fullpage/react-fullpage';
import Header from '../section/Header';
import SectionFirst from "../section/SectionFirst";
import SectionProducts from '../section/SectionProducts';
import SectionReference from '../section/SectionReference';
import SectionFooter from "../section/SectionFooter";
import './Fullpage.css';

// NOTE: if using fullpage extensions/plugins put them here and pass it as props
const pluginWrapper = () => {
    /**
     * require('fullpage.js/vendors/scrolloverflow'); // Optional. When using scrollOverflow:true
     */
};

const anchors = ["header", "content", "footer"];

const Fullpage = props => {
    return (
        <div>
            <Header/>
            <ReactFullpage
                // debug
                anchors={anchors}
                navigation
                pluginWrapper={pluginWrapper}
                navigationTooltips={anchors}
                sectionsColor={["#282c34", "#e5e5e5", "#eeeeee", "#1089ff"]}
                onLeave={(origin, destination, direction) => {
                    console.log("onLeave event", {origin, destination, direction});
                }}
                render={({state, fullpageApi}) => {
                    console.log("render prop change", state, fullpageApi); // eslint-disable-line no-console

                    return (
                        <div>
                            <SectionFirst fullpageApi={fullpageApi} info={"Konštrukcia a výroba nástrojov, výrobky z technickej gumy"}/>
                            <SectionProducts fullpageApi={fullpageApi}/>
                            <SectionReference fullpageApi={fullpageApi} header={"Referencie"}/>
                            <SectionFooter fullpageApi={fullpageApi}/>
                        </div>
                    );
                }}
            />
        </div>
    );
};

export default Fullpage;
