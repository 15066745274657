import React from 'react';
import {Button} from 'antd';

const SectionFooter = props => {
    return (
        <div className='section' id='section4'>
            <h5>IČO: <strong>33727180</strong></h5>
            <h5>DIČ: <strong>1020592441</strong></h5>
            <h5>IČDPH: <strong>SK1020592441</strong></h5>
            <h2>TISA</h2>
            <h4>Hlavná 378, 981 01 Hnúšťa</h4>
            <h3>Telefón: 047 542 3781</h3>
            <h4>email: zoltan@tisa.sk</h4>
            <div className='info'>
                <Button ghost shape="round"
                        icon="up"
                        onClick={() => props.fullpageApi.moveSectionUp()}
                        size='large'>Späť</Button>
            </div>
        </div>
    );
};

export default SectionFooter;
