import React from 'react';
import {Button, Card, Col, Row} from 'antd';

const SectionFirst = props => {
    return (
        <div className='section' id='section1'>
            <h2>{props.info}</h2>
            <Row gutter={8}>
                <Col span={10} offset={2}>
                    <Card title='Profil firmy' bordered={false}>
                        <img className={"right"} src={"/assets/images/Vyrobny-program-technicka-guma.jpg"}
                             alt={"Vyrobny program"}/>
                        <ul className='standard'>
                            <li>Konštrukcia a strojárska výroba lisovacích nástrojov, foriem a prípravkov</li>
                            <li>Výroba náhradných dielov a súčastí z lisovanej technickej gumy a plastov</li>
                            <li>Výrobky z technickej gumy</li>
                            <li>Zákazková výroba lisovanej technickej gumy</li>
                            <li>Technická príprava výroby</li>
                            <li>Výkresové-nenormalizované výrobky</li>
                            <li>Gumokovové výrobky - silentbloky</li>
                            <li>Pogumované kolieska</li>
                            <li>Veľkoobchod so strojárskymi a gumárskymi výrobkami</li>
                        </ul>
                    </Card>
                </Col>
                <Col span={10}>
                    <Card title='Používaný materiál' bordered={false}>
                        <img className={"left"} src={"/assets/images/technicka-guma-lisy.png"}
                             alt={"Lisovanie - technicka guma"}/>
                        <p className='standard'>Základ používaného materiálu tvorí najmä technická guma rôznych vlastností podľa konkrétnej
                            požiadavky zákazníka a podľa nárokov prostredia, v ktorom bude výrobok pracovať.</p>
                        <p className='standard'>Tá sa lisuje pod tlakom vo vulkanizačných formách, ktoré musia byť zhotovené individuálne pre
                            každý výrobok. Ide o zmesi s tvrdosťou od 35 do 95 ShA a s pevnosťou od 2,5 do 21 MPa.</p>
                        <h5 className='firstpage'>Gumové priechodky - výpredaj zásob</h5>
                        <p className='standard'>Stiahnite si zoznam gumových priechodiek - <a href='/files/priechodky_vypredaj_08.pdf'>vypredaj skladových zásob</a></p>
                    </Card>
                </Col>
            </Row>

            <div className='info'>
                <Button ghost
                        type="primary"
                        shape="round"
                        icon="down"
                        onClick={() => props.fullpageApi.moveSectionDown()}
                        size='large'>Pokračuj</Button>
            </div>
        </div>
    );
};

export default SectionFirst;
