import React, {useState} from 'react';
import {Button, Card, Icon} from 'antd';
import ProductCards from "../products/ProductCards";
import json from "../products/products";

const SectionProducts = props => {
    const [productTitleKey, setProductTitleKey] = useState('ltg');

    const tabListProductsTitle = [
        {
            key: 'ltg',
            tab: 'Výrobky z lisovanej technickej gumy',
        },
        {
            key: 'tg',
            tab: 'Výrobky - technická guma',
        },
        {
            key: 'plast',
            tab: 'Výrobky z plastov',
        },
    ];
    const contentListProductTitle = {
        ltg: <ProductCards productCards={json.ltgProductCards} prefix={'ltg'}/>,
        tg: <ProductCards productCards={json.tgProducts} prefix={'ltg'}/>,
        plast: <ProductCards productCards={json.plastProducts} prefix={'plast'}/>,
    };

    const onTabChange = key => setProductTitleKey(key);
    return (
        <div className='section' id='section2'>
            <Card
                style={{width: '70%', 'margin-left': '15%'}}
                tabList={tabListProductsTitle}
                activeTabKey={productTitleKey}
                onTabChange={key => onTabChange(key)}>
                {contentListProductTitle[productTitleKey]}
            </Card>
            <div className='info'>
                <Button.Group size='large'>
                    <Button onClick={() => props.fullpageApi.moveSectionUp()}>
                        <Icon type="up"/>Vyššie
                    </Button>
                    <Button onClick={() => props.fullpageApi.moveSectionDown()}>
                        Nižšie<Icon type="down"/>
                    </Button>
                </Button.Group>
            </div>
        </div>
    );
};

export default SectionProducts;
