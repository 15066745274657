import React from 'react';
import {Button, Card, Col, Icon, Row} from 'antd';

const SectionReference = props => {
    return (
        <div className='section' id='section3'>
            <h2>{props.header}</h2>
            <Row gutter={8}>
                <Col span={10} offset={2}>
                    <Card bordered={false}>
                        <ul className='standard'>
                            <li>TIPRO s.r.o. Brno</li>
                            <li>RENOP s.r.o. Liptovská Teplá</li>
                            <li>ESTE s.r.o. Banská Bystrica</li>
                            <li>Doprastav a.s. Nové Mesto nad Váhom</li>
                            <li>HAKL s.r.o. Ivánka pri Dunaji</li>
                            <li>INTOCAST Slovakia a.s. Košice</li>
                            <li>ROLLER s.r.o. Rimavská Sobota</li>
                            <li>ZETOR a.s. Brno</li>
                            <li>WAY INDUSTRIES s.r.o. Krupina</li>
                            <li>T-GUM s.r.o. Hnúšťa</li>
                            <li>K-Plast s.r.o. Nová Baňa</li>
                            <li>SID s.r.o. Košice</li>
                            <li>KERKO a.s. Michalovce</li>
                        </ul>
                    </Card>
                </Col>
                <Col span={10}>
                    <Card bordered={false}>
                        <ul className='standard'>
                            <li>CWT s.r.o. Rožňava</li>
                            <li>Hutní montáže a.s. Ostrava</li>
                            <li>Chirana Progress s.r.o. Piešťany</li>
                            <li>SMZ a.s. Jelšava</li>
                            <li>A.L.K. s.r.o. Humenné</li>
                            <li>B.P.S. s.r.o. Lučenec</li>
                            <li>NOVOCONSULTING s.r.o. Nové Zámky</li>
                            <li>SLOVMAG a.s. Lubeník</li>
                            <li>EUROKOV s.r.o. Orlov</li>
                            <li>ASKIN s.r.o. Bratislava</li>
                            <li>MAJAPLAST Častá</li>
                            <li>ERBY Košice</li>
                        </ul>
                    </Card>
                </Col>
            </Row>
            <div className='info'>
                <Button.Group size='large'>
                    <Button onClick={() => props.fullpageApi.moveSectionUp()}>
                        <Icon type="up"/>Vyššie
                    </Button>
                    <Button onClick={() => props.fullpageApi.moveSectionDown()}>
                        Nižšie<Icon type="down"/>
                    </Button>
                </Button.Group>
            </div>
        </div>
    );
};

export default SectionReference;