import React from 'react';
import {Card, Col, Row} from "antd";

const {Meta} = Card;

const ProductCards = props => {
    const ProductCol = ({card}) => {
        return (
            <Col key={card.key} span={8}>
                <Card
                    hoverable
                    style={{width: '100%'}}
                    cover={<img alt={card.meta}
                                src={`/assets/images/products/${props.prefix}/${card.img}`}/>}>
                    <Meta title={card.meta}/>
                </Card>
            </Col>
        );
    };

    const ProductRow = ({rows, index}) => {
        return (
            <Row key={index} gutter={[36, 8]}>
                {rows.map(card => <ProductCol card={card}/>)}
            </Row>
        );
    };

    const ProductRows = () => props.productCards.map((rows, index) => <ProductRow rows={rows} index={index}/>);

    return <ProductRows/>;
};

export default ProductCards;